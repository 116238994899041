@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;500;600;700&display=swap');

* {
  margin: 0;
  font-family: 'Noto Sans', sans-serif !important;
}

a {
  text-decoration: none !important;
  /* color: #0F0F43; */
}

p, h1, h2, h3, h4, h5, h6 {
  color: #0F0F43;
}

/* li {
  list-style: none !important;
} */


/* For Hindig Number Input Side Buttons */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media only screen and (max-width:600px) {
  main {
    overflow: auto;
  }
}