
/* ----------------------Exp Card --------------------*/
.mainExpCard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    border: 1px solid #A6A6A6;
    border-radius: 3px;
    border-bottom-left-radius: 0px;
}

.expCardDetails {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex: 50%;
}

.expCardDetails h4 {
    font-weight: 600;
    max-width: 400px;
    text-align: center;
}

.expCardDetails h5, h6 {
    text-align: center;
}

.expCardImageBox {
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 50%;
}

.expCardImageBox img {
    width: 100%;
    height: 100%;
}

.btnBox{
    border:1px solid #A6A6A6;
    border-radius:0;
    border-top:none;
    padding:15px;
    border-bottom-left-radius: 3px;
    display: flex;
}

/* ----------------------Exp Card End --------------------*/

@media only screen and (max-width:1000px) {
    .expCardDetails h4 {
        max-width: 100%;
        font-size: 20px;
    }

    .expCardDetails h5 {
        font-size: 18px;
    }

    .expCardImageBox {
        height: 200px;
    }
}

@media only screen and (max-width:768px) {
    .mainExpCard {
        flex-direction: column-reverse;
        justify-content: center;
        height: 350px;
    }
    .expCardDetails {
        flex: 1;
        height: 200px;
    }

    .expCardImageBox {
        flex: 1;
        object-fit: cover;
    }

    .expCardImageBox img {
        object-fit: cover;
    }

    .expCardDetails h4 {
        max-width: 100%;
        font-size: 24px;
    }

    .expCardDetails h5 {
        font-size: 22px;
    }
}