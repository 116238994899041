.mediaBox {
    display:grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px 0;
}

.mediaBoxImage {
    width: 310px;
    position: relative;
}

.delete {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all .3s ease;
    cursor: pointer;
}

.mediaBoxImage img {
    width: 100%;
    height: 100%;
    box-shadow: 0 0 5px;
    border-radius: 2px;
    transition: all .3s ease;
}

.mediaBoxImage:hover .delete {
    opacity: 1;
}

.mediaBoxImage:hover img {
    filter: blur(2px);
    filter: brightness(30%);
}

@media screen and (max-width: 1260px) {
    .mediaBox {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 768px) {
    .mediaBox {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media screen and (max-width: 500px) {
    .mediaBoxImage {
        width: 250px;
    }
}