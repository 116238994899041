.box {
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-position: center;
}

.logo img {
    width: 300px;
}

.form {
    display: flex !important;
    align-items: center;
    flex-direction: column;
    background-color: #f8f8f8;
    width: 40% !important;
    border-radius: 10px;
    padding: 30px;
}

.formInner {
    margin-top: 20px;
    width: 60%;
}

.formInner input {
    font-size: 20px;
}


.sendOtpButton {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.edit h6 {
    cursor: pointer;
}

.otpInput {
    margin-top: 20px;
    width: 100%;
}

.otpInputBox input {
    width: 2.5rem !important;
    height: 3rem !important;
    margin: 0 0.5rem !important;
    font-size: 2rem !important;
    border: 1px solid #ccc !important;
    outline: none !important;
    text-align: center !important;
    background-color: #f8f7f7 !important;
    border-radius: 5px !important;
}

.otpInput h5 {
    margin-bottom: 10px;
    margin-left: 10px;
}

.resendOtp {
    display: flex;
    justify-content: flex-end;
}

.resendOtp h6 {
    cursor: pointer;
    margin-right: 5px;
    margin-top: 10px;
}

.loginButton {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

.terms {
    padding: 20px 50px;
}

.terms p {
    font-size: 12px;
    color: #0000008a;
}

.footer {
    height: 100px;
    background-color: #0000005e;
    display: flex;
}

.footerContainer {
    align-items: center;
    justify-content: space-between;
}

.footerLeft h6 {
    color: #ffff;
}

.footerRight {
    display: flex;
    align-items: center;
    gap: 50px;
}

.footerRight a svg {
    font-size: 40px;
    transition: all 0.3s ease;
}

.footerRight a:first-child svg path {
    fill: #1519ff;
}

.footerRight a:nth-child(2) svg path {
    fill: #1596ff;
}

.footerRight a:last-child svg path {
    fill: #ff1515;
}

.footerRight a:hover svg {
    transform: scale(1.2);
}

@media only screen and (max-width: 1300px) {
    .form {
        width: 50% !important;
    }

    .formInner {
        width: 60%;
        ;
    }
}

@media only screen and (max-width: 900px) {
    .form {
        width: 70% !important;
    }

    .formInner {
        width: 80%;
        ;
    }
}

@media only screen and (max-width: 700px) {
    .otpInputBox input {
        width: 2.2rem !important;
        height: 2.8rem !important;
        margin: 0 0.3rem !important;
        font-size: 1.8rem !important;
    }
}

@media only screen and (max-width: 500px) {
    .form {
        width: 98% !important;
    }

    .formInner {
        width: 90%;
        ;
    }

    .terms {
        padding: 20px;
    }

    .otpInputBox input {
        width: 2.1rem !important;
        height: 2.7rem !important;
        margin: 0 0.3rem !important;
        font-size: 1.8rem !important;
    }

    .footerLeft h6 {
        font-size: 17px;
    }

    .footerRight {
        gap: 20px;
    }

}